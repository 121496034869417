<template>
    <div class="contract-create-page">
        <div class="contract-create-title">
            <a-row>
                <a-col :span="3">
                    合同退费
                </a-col>
                <a-col :span="18">
                </a-col>
                <a-col :span="3">
                    <a-button type="primary">提交</a-button>
                    <a-button>返回</a-button>
                </a-col>
            </a-row>
        </div>
        <div style="height: 1px"></div>
        <div class="student-profile-row content-item-block">
            <div class="vertical-line title-row">学员信息 <span><a-icon type="arrow-up"/></span></div>

            <div class="content-row student-profile-content">
                <a-row>
                    <a-col :span="3">
                        <a-avatar :size="64" icon="user"/>
                    </a-col>
                    <a-col :span="10">
                        <div><span>汪乐</span><span>乐乐</span><span><a-icon type="man"/></span></div>
                        <div><span>生日 2018-10-24 </span><span>（2岁6月）</span></div>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="3"></a-col>
                    <a-col :span="21">
                        <a-row><span>监护人</span><span>李莉莉（妈妈）  |   13685654526  |  85658545@qq.com</span> <span style="color: #108ee9"  @click="showChangeGuardian=true">更换</span></a-row>
                        <a-row><span>地址 </span><span> 深圳市宝安区宝安中心花年年郡6栋603号</span><span  style="color: #108ee9" @click="showChangeAddr=true">更换</span></a-row>
                    </a-col>
                </a-row>
            </div>
        </div>

        <div class="content-item-block content-plain-block">
            <div class="vertical-line title-row">课程方案</div>
            <div class="plain-radio-group" style="">
                <a-radio-group v-model="createContractForm.plainType" @change="plainTypeChange">
                    <a-radio :default-checked="true" value="usual">常用课程方案</a-radio>
                    <a-radio  :default-checked="false" value="custom">自定义课程方案</a-radio>
                </a-radio-group>
            </div>
            <div  v-if="createContractForm.plainType==='usual'" class="course-plain-row ">
                <div v-if="usualPlainList && usualPlainList.length >0" v-for="item in usualPlainList">
                    <div class=" course-plan-item">
                        <p class="plan-item-title " >{{ item.title }}</p>
                        <p class="info-item-desc " >{{ item.subTitle }}</p>
                        <p class="info-item-desc " >{{ item.desc }}</p>
                    </div>
                </div>
                <div v-else style="text-align: center;width: 100%;margin-top: 30px">
                    <a-row style="margin: 10px auto"><a-avatar shape="square" :size="64" icon="plus" /></a-row>
                    <a-row style="margin: 10px auto">没有添加常用课程方案，管理人员可以添加多个常用的课程方案方便销售人员进行快捷选择</a-row>
                    <a-row style="margin: 40px auto"><a-button>现在去添加</a-button></a-row>
                </div>

            </div>

            <div v-if="createContractForm.plainType==='custom'" class="course-plain-row ">
                <a-form-model     v-model="createContractForm"   class="">
                    <a-form-model-item label="收费标准" >
                        <a-cascader
                            v-model="customPlainForm.feeStandard"
                            :options="feeOptions"
                            :default-value="['1', '11', '111']"
                            style="width:500px"
                        >
                        </a-cascader>
                    </a-form-model-item>
                    <a-form-model-item label="优惠活动(主)"  >
                        <a-select default-value="lucy" v-model="createContractForm.mainActive" style="width: 100%" >
                            <a-select-option value="jack">
                                基础能力满96减100
                            </a-select-option>
                        </a-select>
                        <br>
                        <span>抵减现金 :  100.00</span>
                    </a-form-model-item>
                    <a-form-model-item label="优惠活动(从)"  >
                        <a-select default-value="lucy" v-model="createContractForm.mainActive" style="width: 100%" >
                            <a-select-option value="jack">【10月】双节同庆转介绍赠课4节</a-select-option>
                        </a-select>
                        <br>
                        <span>赠送课时 :  4</span>
                    </a-form-model-item>
                    <br>
                </a-form-model>
                <a-row>+新增优惠方案（从）</a-row>
            </div>
            <a-divider></a-divider>
            <div style="display: flex;justify-content: right;padding-right: 200px">
                <div >
                    <p>有效期： <span>  24个月</span></p>
                    <p>优惠：100元  <span>  赠课：4节</span></p>
                    <p>总计: <span style="font-weight:bold;font-size: 22px ">  19900 </span> 元</p>
                </div>
            </div>
        </div>

        <div class="content-item-block extends-contract-block">
            <div class="vertical-line title-row">其他信息 </div>
            <a-form-model   layout="inline"  v-model="createContractForm" :label-col="{span:10}"   :wrapperCol="{ span: 15 }"  class="content-row extends-contract-form">
                <a-form-model-item label="签单人" style="width: 28%" class="extends-contract-item">
                    <a-select default-value="lucy" v-model="createContractForm.signUser" style="width: 100%" >
                        <a-select-option value="jack">
                            王丽
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="审批人"  style="width: 28%"  class="extends-contract-item">
                    <a-select default-value="lucy" v-model="createContractForm.approveUser" style="width: 100%" >
                        <a-select-option value="jack">
                            张三
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="推广人手机号"  style="width: 28%"  class="extends-contract-item">
                    <a-input v-model="createContractForm.spreaderPhone" />
                </a-form-model-item>
                <br>
                <a-form-model-item label="合同所属中心" style="width: 28%"  class="extends-contract-item">
                    <a-select default-value="lucy" v-model="createContractForm.center" style="width: 100%" >
                        <a-select-option value="jack">
                            深圳百花
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <br>
                <a-form-model-item label='备注' style="width: 28%"  class="extends-contract-item">
                    <a-textarea  v-model="createContractForm.remark" :row="5"  placeholder="请输入备注" />
                </a-form-model-item>


            </a-form-model>
        </div>


        <a-modal
            title="更换监护人"
            :visible="showChangeGuardian"
            @ok="changeGuardianSubmit"
            @cancel="showChangeGuardian=false">
            <a-form-model     v-model="changeStudentForm.Guardiant"    >
                <a-form-model-item label="监护人"  >
                    <a-select   >
                        <a-select-option value="" v-for="item in studentGuardianList">{{item.name}}</a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
export default {
    name: 'ContractDetail',
    data() {
        return {
            showChangeGuardian:false,
            showChangeAddr:false,
            createContractForm: {
                plainType: "usual",
            },
            customPlainForm: {
                feeStandard: [1,11,111],
            },
            changeStudentForm:{

            },
            feeOptions: [
                {
                    value: '1',
                    label: '基础能力课时包',
                    children: [
                        {
                            value: '11',
                            label: '按课时收费',
                            children: [
                                {
                                    value: '111',
                                    label: '96课时',
                                },
                            ],
                        },
                    ],
                },
            ],
            usualPlainList: [
                {
                    title: '基础能力班级课',
                    subTitle: '基础能力满96减100',
                    desc: '10月双节同庆转介绍赠课4节',
                },
                {
                    title: '基础能力班级课',
                    subTitle: '基础能力满96减100',
                    desc: '10月双节同庆转介绍赠课4节',
                },
                {
                    title: '基础能力班级课',
                    subTitle: '基础能力满96减100',
                    desc: '10月双节同庆转介绍赠课4节',
                },
            ],
            studentGuardianList:[
                {name:"学生父亲"},
                {name:"学生母亲"},
                {name:"学生奶奶"},
            ]
        }
    },
    created() {
    },
    mounted() {

    },
    methods: {
        plainTypeChange(e){
            console.log(e.target.value)
        },
        changeGuardianSubmit() {
            this.commissionInfoVisible = true;
        }
    }
}
</script>
<style scoped lang="less">
.contract-create-page {
    box-sizing: border-box;
    margin-bottom: 120px;

    .contract-create-title {
        background-color: white;
        padding-left: 40px;
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 16px;
        font-weight: bold;
    }

    .content-item-block {
        background-color: #fff;
        padding-left: 40px;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-bottom: 10px;

        .vertical-line {
            border-left: #108ee9 solid 4px;
            padding-left: 10px;
        }
    }





    .student-profile-row{
        background-color: #fff;
        padding-top: 30px;
        .student-profile-content{
            border-radius: 2px;
            margin-top:  40px;
            margin-bottom: 40px;
            padding: 20px;
            border: 1px solid lightgrey;
            min-width:500px ;
            max-width:800px ;
        }
    }



    .content-plain-block{
        background-color: white;

        .plain-radio-group{
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .course-plain-row{
            margin-bottom: 40px;
            display: flex;
            justify-content: left;
            .course-plan-item{
                margin-right: 20px;
                padding: 10px;
                text-align: left;
                width: 400px;
                border-radius: 5px;
                border: 1px #108ee9 solid;
                .plan-item-title{
                    font-size: 18px;
                    font-weight: bold;
                }
                .info-item-desc{
                    font-size: 12px;

                }
            }
        }
    }

    .extends-contract-block{
        .extends-contract-form{
            margin-top: 15px ;
        }
        .extends-contract-item{
            margin: 15px  0;
            width: 28%;
        }
    }


}


</style>
